const checkEnv = () => {
  const hostname = window && window.location && window.location.hostname;
  let env = {
    // baseURL: "https://apidev.i-dealhomeonline.com/admin",
    // basev2URL: "https://apidev.i-dealhomeonline.com/merchant",
    // baseURL: "https://api.i-dealhomeonline.com/admin",
    // basev2URL: "https://apiprod-admin.i-dealhomeonline.com/merchant",
    baseURL: "http://localhost:5500/admin",
    basev2URL: "http://localhost:5500/merchant",
    aws: {
      bucketName: 'ideal-homeonline-staging',
      accessKeyId: `${process.env.REACT_APP_IDEAL_AWS_ACCESS_KEY_ID}`, 
      secretAccessKey: `${process.env.REACT_APP_IDEAL_AWS_SECRET_ACCESS_KEY}`,
      secretKeyId: `${process.env.REACT_APP_IDEAL_AWS_SECRET_ACCESS_KEY}`,
    }
  }
  if (
    hostname &&
    (hostname.includes('merchant.i-dealhomeonline.com') ||
      hostname.includes('merchant-v2.i-dealhomeonline.com') ||
      hostname.includes('idealmerchant.netlify.app') ||
      hostname.includes('merchant-v2.netlify.app')) 
  ) {
    env = {
      baseURL: "https://api.i-dealhomeonline.com/admin",
      basev2URL: "https://apiprod-admin.i-dealhomeonline.com/merchant",
      aws: {
        bucketName: 'ideal-homeonline-production',
        accessKeyId: `${process.env.REACT_APP_IDEAL_AWS_ACCESS_KEY_ID}`, 
        secretAccessKey: `${process.env.REACT_APP_IDEAL_AWS_SECRET_ACCESS_KEY}`,
        secretKeyId: `${process.env.REACT_APP_IDEAL_AWS_SECRET_ACCESS_KEY}`,
      }
    }
  }
  if (
    hostname && (hostname.includes('merchant-v2.i-dealhomeonline.com'))
  ) {
    env = {
      baseURL: "https://api.i-dealhomeonline.com/admin",
      basev2URL: "https://apiprod.i-dealhomeonline.com/merchant",
      aws: {
        bucketName: 'ideal-homeonline-production',
        accessKeyId: `${process.env.REACT_APP_IDEAL_AWS_ACCESS_KEY_ID}`, 
        secretAccessKey: `${process.env.REACT_APP_IDEAL_AWS_SECRET_ACCESS_KEY}`,
        secretKeyId: `${process.env.REACT_APP_IDEAL_AWS_SECRET_ACCESS_KEY}`,
      }
    }
  }
  return env
}

export default checkEnv();

// export default {
//   // baseURL: "https://api.i-dealhomeonline.com/admin",
//   // basev2URL: "https://apiprod-admin.i-dealhomeonline.com/merchant",
//   // baseURL: "https://apidev.i-dealhomeonline.com/admin",
//   // basev2URL: "https://apidev.i-dealhomeonline.com/merchant", 
// }